<template lang="pug">
.alert.alert-danger.text-center.mb-0.p-0(role="alert")
  .box-center.d-flex.justify-content-center
    .icon
      UilExclamationTriangle
    .text
      span {{ $t('priceChangeAlert.shopify.line1') }}
    .button
      om-button(primary :loading="isActionInProgress" @click="redirectToPlanSettings") {{ $t('priceChangeAlert.shopify.redirect') }}
</template>

<script>
  import { UilExclamationTriangle } from '@iconscout/vue-unicons';

  export default {
    components: {
      UilExclamationTriangle,
    },
    computed: {
      isActionInProgress() {
        if (this.$route.query.updateRecurringPlan) return true;
        return false;
      },
    },
    methods: {
      redirectToPlanSettings() {
        this.$router.replace({
          name: 'plan_settings',
          query: { updateRecurringPlan: true },
        });
      },
    },
  };
</script>
